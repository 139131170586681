import '@fancyapps/fancybox';
import Swiper from 'swiper';
import svg4everybody from 'svg4everybody';
import LazyLoad from "vanilla-lazyload";
import Masonry from 'masonry-layout';

(function ($) {
	$(function () {
		svg4everybody();

		if ($('.grid').length) {
			const msnry = new Masonry(document.querySelector('.grid'), {
				// options
				itemSelector: '.grid__item'
			});
		}


		const lazyLoadInstance = new LazyLoad({
			elements_selector: ".lazy-picture",
		});

		const $goodMain = $('.goodSlider--main');
		const $goodThumbs = $('.goodSlider--thumbs');
		const $thumbsSlides = $goodThumbs.find('.swiper-slide');

		let goodMain;

		const goodThumbs = new Swiper('.goodSlider--thumbs .swiper-container', {
			observer: true,
			observeParents: true,
			slidesPerView: 'auto',
			spaceBetween: 5,
			slideToClickedSlide: true,
			// centeredSlides: true,
			touchRatio: 0,

			breakpoints: {
				700: {
					spaceBetween: 0,
				}
			},
			on: {
				slideChange() {
					goodThumbs.slideTo(goodMain.activeIndex);
					$goodThumbs.find('.swiper-slide').removeClass('is-active');
					$thumbsSlides.eq(goodMain.activeIndex).addClass('is-active');
				}
			}
		});

		goodMain = new Swiper('.goodSlider--main .swiper-container', {
			observer: true,
			observeParents: true,
			watchOverflow: true,
			on: {
				init() {
					$thumbsSlides.eq(this.activeIndex).addClass('is-active');
				},
				slideChange() {
					goodThumbs.slideTo(goodMain.activeIndex);
					$goodThumbs.find('.swiper-slide').removeClass('is-active');
					$thumbsSlides.eq(goodMain.activeIndex).addClass('is-active');
				}
			}
		});

		$thumbsSlides.on('click', function () {
			const $thumbsSlide = $(this);
			const index = $thumbsSlides.index($thumbsSlide);
			goodMain.slideTo(index);
		});


		/* slider 2 */

		$('.contentCardSlider').each(function () {
			const $self = $(this);
			const $mainSliderContent = $self.find('.contentCardSlider__main');
			const $thumbsSliderContent = $self.find('.contentCardSlider__thumbs');
			const $thumbsSlidesContent = $thumbsSliderContent.find('.swiper-slide');
			let mainSlider;

			const thumbsSlider = new Swiper($thumbsSliderContent, {
				observer: true,
				observeParents: true,
				slidesPerView: 3,
				spaceBetween: 20,
				slideToClickedSlide: true,
				// centeredSlides: true,
				touchRatio: 0,
				breakpoints: {
					1024: {
						slidesPerView: 4,
						centeredSlides: true,
						touchRatio: 0.25,
					}
				},
				on: {
					slideChange() {
						mainSlider.slideTo(thumbsSlider.activeIndex);
						// $goodThumbs.find('.swiper-slide').removeClass('is-active');
						// $thumbsSlides.eq(goodMain.activeIndex).addClass('is-active');
					}
				}
			});

			mainSlider = new Swiper($mainSliderContent, {
				observer: true,
				observeParents: true,
				pagination: {
					clickable: true,
					el: $mainSliderContent.find('.swiper-pagination')
				},
				navigation: {
					nextEl: $mainSliderContent.parent().find('.swiper-button-next'),
					prevEl: $mainSliderContent.parent().find('.swiper-button-prev'),
				},
				on: {
					init() {
						$thumbsSlidesContent.eq(this.activeIndex).addClass('is-active');
					},
					slideChange() {
						thumbsSlider.slideTo(mainSlider.activeIndex);
						$thumbsSliderContent.find('.swiper-slide').removeClass('is-active');
						$thumbsSlidesContent.eq(mainSlider.activeIndex).addClass('is-active');
					}
				}
			});

			$thumbsSlidesContent.on('click', function () {
				const $thumbsSlide = $(this);
				const index = $thumbsSlidesContent.index($thumbsSlide);
				mainSlider.slideTo(index);
			});
		});


		$('.js-anchor-link-content')
			.on('click', function (e) {
				e.preventDefault();

				$('html, body')
					.animate({
						scrollTop: $($(this)
							.attr('href'))
							.offset().top - $('.contentCard__nav').outerHeight()
					});

				$('.js-anchor-link-content').parent().removeClass('is-active');
				$(this).parent().addClass('is-active');

			});


		$('.textBlock__moreLink').on('click', function (e) {
			e.preventDefault();
			$(this).closest('.textBlock').addClass('is-active');
		});


		const dropDownSlider = new Swiper('.dropdownSlider .swiper-container', {
			touchRatio: 0,
			slidesPerView: 1,
			effect: 'fade',
			observer: true,
			observeParents: true,
			pagination: {
				clickable: true,
				el: '.dropdownSlider .swiper-pagination'
			},
			breakpoints: {
				1024: {
					touchRatio: 0.5
				}
			},
			on: {
				slideChange() {
					$('.dropdownMenu__item').removeClass('is-active');
					$('.dropdownMenu__item').eq(this.activeIndex).addClass('is-active');
				}
			}
		});

		$('.dropdownMenu__item > a').hover(function () {
			dropDownSlider.slideTo($(this).parent().index());
		});

		if ($(window).width() <= 1024) {

			$('[data-src="#catalogMenu"]').fancybox({
				afterLoad() {
					$('.dropdownSlider .swiper-container')[0].swiper.update();

					$('.fancybox-slide .dropdownMenu__item > a').hover(function () {
						$('.dropdownSlider .swiper-container')[0].swiper.slideTo($(this).parent().index());
					});
				}
			})
		}

		/*eslint-disable*/
		let topMenu = $(".contentCard__nav");
		let topMenuHeight = topMenu.outerHeight() + 15;
		let menuItems = topMenu.find("a");
		let scrollItems = menuItems.map(function () {
			var item = $($(this).attr("href"));
			if (item.length) {
				return item;
			}
		});

		$(window).on('scroll', function () {

			if ($(window).scrollTop() > 800) {
				$('.scrollUp').addClass('is-active');
			} else {
				$('.scrollUp').removeClass('is-active');
			}

			if ($('.scrollColumn').length) {
				$('.scrollColumn .scrollColumn__fill').css({
					height: $(window).scrollTop() - $('.scrollColumn').offset().top + ($(window).height() / 2) + 'px'
				})
				// if ($(window).scrollTop() >= $('body').outerHeight() - $(window).outerHeight()) {
				// $('.scrollColumn .scrollColumn__fill').css({
				// 	height: '100%'
				// })
				// } else {
				// }

			}

			const $navContent = $('.contentCard__nav');
			if ($navContent.length) {
				if ($(window).scrollTop() >= $navContent.offset().top && !$navContent.hasClass('is-fixed')) {
					$navContent.addClass('is-fixed');
					$navContent.next().css('padding-top', $navContent.outerHeight());
				} else if ($(window).scrollTop() < $('.contentCard__list').offset().top) {
					$navContent.removeClass('is-fixed');
					$navContent.next().css('padding-top', 0);
				}

				var fromTop = $(this).scrollTop() + topMenuHeight;

				// Get id of current scroll item
				var cur = scrollItems.map(function () {
					if ($(this).offset().top < fromTop)
						return this;
				});
				// Get the id of the current element
				cur = cur[cur.length - 1];
				var id = cur && cur.length ? cur[0].id : "";
				// Set/remove active class
				menuItems
					.parent().removeClass("is-active")
					.end().filter("[href='#" + id + "']").parent().addClass("is-active");
			}
		});


		$('.scrollUp').on('click', function (e) {
			e.preventDefault();

			$('html, body')
				.animate({
					scrollTop: 0
				});
		});

		$('.listDefaultToggle a').on('click', function(e){
		    e.preventDefault();

		    $(this).closest('.listDefaultContainer').addClass('is-active');
		});
	});
})(jQuery);
